import React from "react";

import ProductDetails from "../../../components/pages/Shop/ProductDetails";

const ShopArtikelPage = ({ params: { slug } }) => {
    return (
        <>
            <ProductDetails productId={slug} />
        </>
    );
};

export default ShopArtikelPage;
